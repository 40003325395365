.charts {
  margin-top: 80px;
}

.MuiToggleButtonGroup-root {
  margin-right: 16px;
}

.MuiToggleButton-root {
  background-color: white !important;
  color: black !important;
}

.MuiToggleButton-root.Mui-selected {
  background-color: #43b998 !important;
  color: white !important;
}

.toolbar {
  max-width: 1000px;
  margin: auto;
  width: 100%;
}

.chartContainer {
  margin: auto;
  position: relative;
  padding: 0 10px 25px;
  width: 98vw;
  height: 88vh;
  max-width: 1080px;
  right: 10px;
}

.menuButton {
  color: white !important;
  display: none !important;
}

.MuiToggleButtonGroup-root {
  display: none !important;
}
.menuButton {
  display: flex !important;
}
@media only screen and (max-width: 1023px) {
}